import { LoadingSpinner, lazyRetry } from '@airelogic/common';
import axios from 'axios';
import { Suspense, useEffect, useState } from 'react';
import { LiveAnnouncer, LiveMessage } from 'react-aria-live';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

const SinglePass = lazyRetry(() => import('./SinglePass/SinglePass'));
const ClinicianFacing = lazyRetry(() => import('./ClinicianFacing/ClinicianFacing'));
const LandingPage = lazyRetry(() => import('./LandingPage/LandingPage'));
const Pods = lazyRetry(() => import('./Pods/Pods'));

const App = () => {
  const [currentTitle, setCurrentTitle] = useState(document.title);

  useEffect(() => {
    const parsedParams = new URLSearchParams(window.location.search);
    if (parsedParams.has('jwt')) {
      axios.defaults.headers.common.Authorization = `Bearer ${parsedParams.get('jwt')}`;
    }

    const observer = new MutationObserver(function (mutations) {
      setCurrentTitle(mutations[0].target.textContent || 'AireForms');
    });
    observer.observe(document.querySelector('title')!, {
      subtree: true,
      characterData: true,
      childList: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Suspense fallback={<LoadingSpinner fullscreen={true} />}>
      <LiveAnnouncer>
        <LiveMessage message={currentTitle} aria-live="polite" />
        <BrowserRouter>
          <Switch>
            <Route path="/launch/:orgCode">
              <LandingPage />
            </Route>
            <Route path="/patient/:patientId/instance/:instanceId">
              <ClinicianFacing />
            </Route>
            <Route path="/patient/:patientId/instance/:instanceId/version/:versionId?">
              <ClinicianFacing />
            </Route>
            <Route path="/:orgCode/singlepass/:form/:version?">
              <SinglePass />
            </Route>
            <Route path="/forms/:form/:version?">
              <SinglePass />
            </Route>
            <Route path="/pod/:orgCode">
              <Pods />
            </Route>
            <Route path="*">Not found</Route>
          </Switch>
        </BrowserRouter>
      </LiveAnnouncer>
    </Suspense>
  );
};

export default App;
